import React from 'react';
import { css } from 'glamor';
import { View, Text, ButtonWithLoading, Link } from '../../components/common';
import Layout from '../../components/layout';
import { SMALL } from '../../components/content';
import config from '../../config';
import { colors } from '../../style';

class LoginPage extends React.Component {
  state = { email: '', sending: false, sent: false, error: null };

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = async e => {
    e.preventDefault();

    this.setState({ sending: true, error: null });
    let res = await fetch(config.endpoint + '/send-manage-email', {
      method: 'POST',
      body: JSON.stringify({ email: this.state.email }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json());

    if (res.status !== 'ok') {
      this.setState({ sending: false, error: res.reason });
    } else {
      this.setState({ sending: false, sent: true, email: '' });
    }
  };

  renderError(error) {
    let msg;
    switch (error) {
      case 'not-found':
        msg = (
          <Text style={{ display: 'block', width: 400 }}>
            No account exists with that email. If you
            {"'"}
            ve previously signed up, you can simply <Link to="/pricing">subscribe again</Link>.
          </Text>
        );
        break;
      case 'email-problems':
        msg = (
          <Text style={{ display: 'block', width: 400 }}>
            We had problems sending you email. Please contact{' '}
            <a href="mailto:help@actualbudget.com" style={{ color: colors.p4 }}>
              help@actualbudget.com
            </a>{' '}
            for support.
          </Text>
        );
        break;
      default:
        msg = (
          <Text>
            We hit a snag, sorry! Email{' '}
            <a href="mailto:help@actualbudget.com" style={{ color: colors.p4 }}>
              help@actualbudget.com
            </a>{' '}
            for help
          </Text>
        );
    }

    return (
      <Text className="mt-4 bg-red-200 text-red-900 p-1 px-3 rounded link-color-inherit">
        {msg}
      </Text>
    );
  }

  render() {
    let { email, sending, sent, error } = this.state;

    return (
      <Layout>
        <View style={{ alignItems: 'center' }}>
          <Text className="text-2xl font-bold mt-20">Manage Account</Text>
          <View
            style={{
              width: 600,
              alignItems: 'center',
              padding: 20,
              [SMALL]: {
                width: 'auto',
                padding: 15
              }
            }}
          >
            Enter your email and we{"'"}ll send you a link to manage your account.
            {error && this.renderError(error)}
            {sent && <Text style={{ marginTop: 20, color: colors.g3 }}>Email sent!</Text>}
            <form
              onSubmit={this.onSubmit}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20
              }}
            >
              <input
                className="border border-gray-400"
                {...css({
                  borderRadius: 4,
                  padding: '8px 10px',
                  marginRight: 15,
                  width: 350,
                  [SMALL]: {
                    width: 200
                  }
                })}
                autoFocus
                placeholder="Email"
                onChange={this.onChange}
                value={email}
              />
              <ButtonWithLoading loading={sending} primary>
                Go
              </ButtonWithLoading>
            </form>
          </View>
        </View>
      </Layout>
    );
  }
}

export default LoginPage;
